<template>
  <div class="web-content">
    <Search
      :termlist="termlist"
      :classlist="classlist"
      ref="search"
      @handleQuery="handleQuery"
    />
    <el-row type="flex" justify="space-between">
      <el-col :span="6">
        <h3>学业水平</h3>
      </el-col>
      <el-col :span="18" class="btn" style="text-align: right">
        <el-button
          type="primary"
          style="display: inline-block; margin-right: 10px"
          plain
          ><download-excel
            :data="tableData"
            :fields="fields"
            name="学业水平.xls"
          >
            导出
          </download-excel>
        </el-button>
        <el-button type="primary" plain @click="handleRaceGroup"
          >批量添加比赛</el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :header-cell-style="tableHeaderColor"
      :cell-style="cellStyleFun"
    >
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column prop="userName" label="学生姓名"> </el-table-column>
      <el-table-column prop="studentCode" label="学籍号" width="180">
      </el-table-column>
      <el-table-column prop="className" label="班级" width="120">
      </el-table-column>
      <el-table-column prop="realyCount" label="写实记录" width="120">
      </el-table-column>
      <el-table-column prop="comExamCount" label="平时成绩" width="120">
      </el-table-column>
      <el-table-column prop="midExamCount" label="期中成绩" width="120">
      </el-table-column>
      <el-table-column prop="finExamCount" label="期末成绩" width="120">
      </el-table-column>
      <el-table-column prop="subMatchCount" label="学科比赛" width="120">
      </el-table-column>
      <el-table-column prop="showCount" label="课堂表现评价" width="120">
      </el-table-column>
      <el-table-column prop="sumScore" label="本维度综素成绩（分）" width="180">
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="150">
        <template slot-scope="scope">
          <el-button @click="handleDetail(scope.row)" type="text" size="small"
            >查看详情</el-button
          >
          <el-button @click="handleRaceAdd(scope.row)" type="text" size="small"
            >添加比赛</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加学科比赛-->
    <el-dialog
      :title="title"
      :visible.sync="openAdd"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      width="600px"
      center
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px"
        label-position="left"
        style="
          border-top: 1px solid #e1e3e9;
          border-bottom: 1px solid #e1e3e9;
          padding-top: 5px;
        "
      >
        <el-form-item
          v-if="!isGroup"
          label-width="0"
          style="border-bottom: 1px dashed #e1e3e9"
        >
          <span class="sname">{{ form.userName }}</span>
          <span class="snumb">学籍号：{{ form.studentCode }}</span>
        </el-form-item>
        <el-form-item label="比赛名称：" prop="matchName">
          <el-input v-model="form.matchName"></el-input>
        </el-form-item>
        <el-form-item label="比赛级别：" prop="indexRuleLevel">
          <el-select
            style="width: 100%"
            v-model="form.indexRuleLevel"
            placeholder="请选择级别"
          >
            <el-option
              v-for="(item, index) in matchLevelList"
              :key="index"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
		<el-form-item label="获奖等级：" prop="resultLevel">
			<el-input placeholder="请输入获奖等级" v-model="form.resultLevel"></el-input>
		</el-form-item>
        <el-form-item label="比赛时间：" prop="matchTime">
          <el-date-picker
            v-model="form.matchTime"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期时间"
            style="width: 100%"
						:picker-options="ltToday"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="举办单位：" prop="awardedUnit">
          <el-input v-model="form.awardedUnit"></el-input>
        </el-form-item>

        <!-- 批量添加 start -->
        <el-form-item label="参与学生" prop="studentIds" v-if="isGroup">
          <div class="student-box">
            <el-row
              type="flex"
              justify="space-around"
              style="border-bottom: 1px solid #e1e3e9"
            >
              <el-col style="padding-left: 15px">
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="checkAll"
                  @change="handleCheckAllChange"
                  >全选</el-checkbox
                >
              </el-col>
              <el-col style="text-align: right; padding-right: 15px">
                已选<span style="color: #569df8">{{ students }}</span
                >人
              </el-col>
            </el-row>
            <ul style="padding: 0px 15px">
              <el-checkbox-group
                v-model="form.studentIds"
                @change="handleCheckedCitiesChange"
              >
                <p v-if="classStudent.length <= 0">暂无数据</p>
                <li v-for="(stu, sin) in classStudent" :key="sin">
                  <el-checkbox :label="stu.userId"
                    >{{ stu.userName }} {{ stu.loginName }}</el-checkbox
                  >
                </li>
              </el-checkbox-group>
            </ul>
          </div>
        </el-form-item>
        <!-- 批量添加  end -->

        <el-form-item label="所在学期：">
          <span class="scontent">{{ form.termName }}</span>
        </el-form-item>
        <el-form-item label="所在学校：" v-if="!isGroup">
          <span class="scontent">{{ form.schoolName }}</span>
        </el-form-item>
        <el-form-item label="佐证材料：">
          <el-upload
            action="nothing"
            accept=".jpg,.png,.doc,.docx,.xls,.xlsx,.pdf,.txt"
            :limit="3"
            :multiple="true"
            :file-list="form.fileList"
            :on-change="handleUpChange"
            :on-remove="handleRemove"
            :on-exceed="handleExceed"
            :auto-upload="false"
          >
            <el-button size="small" type="primary">添加附件</el-button>
            <div slot="tip" class="el-upload__tip">
              <!-- 只能上传jpg/png文件，且不超过1MB，最多三张 -->
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注说明：">
          <el-input
            v-model="form.bz"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6 }"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openAdd = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Search from "@/components/Search";
import {
  academicList,
  addRaceGroup,
  addProjectRace,
} from "@/api/teacher/quality/Academic";
import { getMatchLevel } from "@/api/teacher/quality/artistic";
import { getClassStudent } from "@/api/record/group/group";
export default {
  data() {
    return {
      tableData: [],
      title: "",
      openAdd: false,
      form: {
        studentIds: [],
      },
      rules: {
        matchName: [
          { required: true, message: "请输入比赛名称", trigger: "blur" },
        ],
        indexRuleLevel: [
          { required: true, message: "请选择比赛级别", trigger: "change" },
        ],
        matchTime: [
          { required: true, message: "请选择比赛时间", trigger: "blur" },
        ],
        awardedUnit: [
          { required: true, message: "请输入举办单位", trigger: "blur" },
        ],
        studentIds: [
          { required: true, message: "请选择参与学生", trigger: "change" },
        ],
      },
      total: 0,
      isGroup: false,
      checkAll: false,
      isIndeterminate: false,
      classStudent: [],
      students: 0,
      userIds: [],
      queryParams: {
        pageSize: 100,
      },
      typeId: "309",
      termlist: [],
      classlist: [],
      matchLevelList: [],
      fields: {
        学生姓名: "userName",
        学籍号: "studentCode",
        班级: "className",
        写实记录: "realyCount",
        平时成绩: "comExamCount",
        期中成绩: "midExamCount",
        期末成绩: "finExamCount",
        学科比赛: "subMatchCount",
        课堂表现评价: "showCount",
        "本维度综素成绩（分）": "sumScore",
      },
    };
  },
  components: {
    Search,
  },
  created() {
    // this.queryParams.termName = this.$store.getters.queryForm.termName
    // this.queryParams.classId = this.$store.getters.queryForm.deptId
    // console.log(this.queryParams);
    // this.getList()
  },
  methods: {
    getFirst() {
      this.$store.dispatch("GetTermList").then((res) => {
        this.termlist = res.data;
      });
      this.$store.dispatch("GetClassList", this.typeId).then((res) => {
        this.classlist = res.data;
        this.queryParams.termName = this.$store.getters.queryForm.termName;
        this.queryParams.classId = this.$store.getters.queryForm.deptId;
        this.$refs.search.init(
          this.queryParams.termName,
          this.queryParams.classId
        );
        this.getList();
      });
    },
    getList() {
      academicList(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.size;
      });
    },
    handleQuery(data) {
      this.queryParams.classId = data.deptId;
      this.queryParams.termName = data.term;
      this.queryParams.searchStr = data.userName;
      this.getList();
    },
    handleDetail(row) {
      const params = {
        userId: row.userId,
        userName: row.userName,
        studentCode: row.studentCode,
        termName: this.queryParams.termName,
        className: row.className,
        classId: this.queryParams.classId,
      };
      this.$router.push({ name: "academicStudent", params: params });
      sessionStorage["params"] = JSON.stringify(params);
    },
    handleRaceAdd(row) {
      this.title = "添加学科比赛";
      this.openAdd = true;
      this.isGroup = false;
      this.matchLevel();
      this.reset();
      this.form.userName = row.userName;
      this.form.userId = row.userId;
      this.form.studentCode = row.studentCode;
      this.form.classId = this.queryParams.classId;
      this.form.termName = this.queryParams.termName;
      this.form.schoolName = row.deptName;
    },
    handleSubmit() {
      if (this.form.file) {
        this.form.file = this.compressImg(this.form.file);
      }
      setTimeout(() => {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            var formData = new FormData();
            if (this.form.file != undefined) {
              this.form.file.map((item) => {
                formData.append("file", item);
              });
            }
            formData.append("classId", this.queryParams.classId);
            formData.append("matchName", this.form.matchName);
            formData.append("indexRuleLevel", this.form.indexRuleLevel);
            formData.append("matchTime", this.form.matchTime);
            formData.append("awardedUnit", this.form.awardedUnit);
            formData.append("bz", this.form.bz);
			formData.append('resultLevel', this.form.resultLevel)
            if (this.isGroup) {
              formData.append("userIds", this.form.studentIds.join(","));
              addRaceGroup(formData).then((res) => {
                this.$message.success("添加成功");
                this.getList();
                this.openAdd = false;
              });
            } else {
              formData.append("userId", this.form.userId);
              addProjectRace(formData).then((res) => {
                this.$message.success("添加成功");
                this.getList();
                this.openAdd = false;
                this.form.fileList = [];
              });
            }
          } else {
            return false;
          }
        });
      }, 1000);
    },
    handleRaceGroup() {
      this.title = "批量添加学科比赛";
      this.openAdd = true;
      this.isGroup = true;
	  this.isIndeterminate = false
	  this.checkAll = false
	  this.students = 0
      this.reset();
      this.form.classId = this.queryParams.classId;
      this.form.termName = this.queryParams.termName;
      this.getStudent();
      this.matchLevel();
    },
    // 获取学生列表
    getStudent() {
      getClassStudent({ deptId: this.queryParams.classId }).then((res) => {
        this.classStudent = res.data;
      });
    },
    // 全选
    handleCheckAllChange(val) {
      const arr = [];
      this.form.studentName = "";
      if (val) {
        this.classStudent.map((item) => {
          arr.push(item.userId);
          this.form.studentName += item.userName + ",";
        });
        this.students = arr.length;
      } else {
        this.students = 0;
      }
      this.form.studentIds = val ? arr : [];
      this.isIndeterminate = false;
      // console.log(this.form.studentName, "全选");
    },
    // 单选
    handleCheckedCitiesChange(value) {
      this.form.studentName = "";
      this.students = value.length;
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.classStudent.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.classStudent.length;
      value.map((check) => {
        this.classStudent.map((item) => {
          if (check == item.userId) {
            this.form.studentName += item.userName + ",";
          }
        });
      });
      // console.log(this.form.studentName, "单选");
    },
    reset() {
      this.form = {
        studentIds: [],
        userId: '',
        classId: '',
        userName: '',
        studentCode: '',
        matchName: '',
        indexRuleLevel: '',
        matchTime: '',
        awardedUnit: '',
        termName: '',
        schoolName: '',
        fileList: [],
        file: null,
        bz: '',
      };
      this.resetForm("form");
    },
    // 上传图片
    handleUpChange(file, fileList) {
      var _this = this;
      _this.form.file = fileList;
      if (file.size / 1024 > 5 * 1024) {
        this.$message.warning("文件大小不得超过5MB");
        this.handleRemove(file);
      }
    },
    handleRemove(file, fileList) {
      this.form.file.forEach((item, index) => {
        if (item.name === file.raw.name) {
          this.form.file.splice(index, 1);
        }
      });
    },
    handleExceed() {
      this.$message.warning("最多只能上传三个文件");
    },
    matchLevel() {
      getMatchLevel({ type: "matchLevel" }).then((res) => {
        this.matchLevelList = res.data;
      });
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #616266;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/el-tabs.scss";
@import "@/assets/scss/studentResult.scss";
.web-content {
  width: 100%;

  .student-box {
    max-height: 343px;
    background: #f9fafc;
    border: 1px solid #e1e3e9;
    border-radius: 4px;
    overflow: auto;
  }
}
</style>
<style scoped>
.el-form-item {
  margin-bottom: 16px;
}
.el-form-item >>> .el-form-item__label {
  font-weight: bold;
  color: #303133;
}
</style>